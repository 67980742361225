@use '@angular/material' as mat;
@import 'themes/material/variables';
@import 'themes/material/palettes';

// Create the light theme object, using the default light palette of angular material

$elliot-boilerplate-light-theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,

      accent: $accent,

      warn: $warn,
    ),

    typography: $custom-typography,

    density: 0,
  )
);

/*
Include theme styles for core and each component used in your app.

Alternatively, you can import and @include the theme mixins for each component that you are using.

As of v15 mat.legacy-core no longer includes default typography styles. So it's also included

Mat-all-legacy-component-themes is used over mat-all-component-themes since it will also import all component

themes from older versions of Angular Material

*/
@include mat.all-component-typographies($custom-typography);

@include mat.all-component-themes($elliot-boilerplate-light-theme);

@include mat.core();
