//! --- Section of primeNG --- //
//? Custom table
// Table
.p-datatable .p-datatable-thead > tr {
  background: var(--secondary);
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 0.5rem;
  border: none;
  font-weight: normal;
  background: var(--secondary);
  color: var(--white);
}

.p-datatable .p-datatable-thead > tr > th:first-child {
  padding-left: 1.4rem;
  border-radius: 18px 0 0 18px;
}

.p-datatable .p-datatable-thead > tr > th:last-child {
  padding-right: 1.4rem;
  border-radius: 0 18px 18px 0;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: var(--white);
  margin-left: 0.5rem;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background: var(--secondary);
  color: var(--white);
}

.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: var(--white);
}

.p-datatable .p-datatable-tbody > tr {
  background: var(--white);
  color: var(--secondary);
}

.p-datatable .p-datatable-tbody > tr:nth-child(even) {
  background: var(--tableGrey);
  color: var(--secondary);
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.5em;
  border: none;
  font-weight: normal;
  text-align: left;
}

// .p-datatable .p-datatable-tbody > tr > td > img {
//   width: 20px;
// comento esto porque Laura me ha pasado todos los iconos de la tabla con el mismo tamaño
// para que no haya probelmas en con el width y height
// }

.p-datatable .p-datatable-tbody > tr > td > img:first-child {
  margin-right: 1rem;
}

.p-datatable .p-datatable-tbody > tr > td > p {
  height: auto;
}

.p-datatable .p-datatable-tbody > tr > td:first-child {
  padding-left: 1.4rem;
  border-radius: 34px 0 0 34px;
}

.p-datatable .p-datatable-tbody > tr > td:last-child {
  padding-right: 1.4rem;
  border-radius: 0 34px 34px 0;
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  color: var(--white);

  img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%) hue-rotate(11deg)
      brightness(104%) contrast(100%);
  }
}

// Paginator
.p-datatable .p-paginator-top,
.p-datatable .p-paginator-bottom {
  border: none;
}

.p-paginator {
  padding: 1rem 1rem 0.5rem;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  color: var(--secondary);
  min-width: 2rem;
  height: 2rem;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: var(--primary);
  border-color: none;
  color: var(--white);
  min-width: 2rem;
  height: 2rem;
}

.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: var(--primaryOpacity);
  color: var(--white);
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  color: var(--secondary);
  min-width: 2rem;
  height: 2rem;
}

.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: var(--primaryOpacity);
  color: var(--white);
}

.p-paginator .p-disabled {
  color: grey;
}

// Dropdown paginator table
.p-paginator .p-dropdown {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  height: 2rem;
  border-radius: 16px;
  width: auto;

  .p-dropdown-label {
    padding: 5px 0px 5px 15px;
  }

  .p-dropdown-panel {
    .p-dropdown-items .p-dropdown-item {
      padding: 0.5rem 0.5rem;
    }
  }

  .p-dropdown-trigger {
    width: 2.5rem;
  }
}

// filter of column in the table
.p-column-filter-menu-button {
  color: var(--primary);
  border-color: transparent;
  height: auto;
}

.p-column-filter-menu-button:hover {
  color: var(--primary);
  border-color: transparent;
  background: none;
}

.p-column-filter-menu-button.p-column-filter-menu-button-open,
.p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
  background: none;
  color: var(--primary);
}

//? Dropdown global
.p-dropdown {
  border: 1px solid var(--grey);
  border-radius: 0 21px 21px 0;
  height: 2.5rem;
  align-items: center;
  width: 15rem;

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--secondary);
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background: var(--grey);
    border-radius: 4px;
    margin: 1rem;
  }

  .p-dropdown-panel {
    border-radius: 8px;
    box-shadow: 0px 3px 6px var(--secondaryShadow);

    .p-dropdown-items {
      padding: 0.5rem 0;

      .p-dropdown-item {
        padding: 0.5rem 1.25rem;
      }

      .p-dropdown-item.p-highlight,
      .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
        color: var(--primary);
        background: transparent;
      }
    }

    .p-dropdown-header {
      padding: 0.75rem 1.25rem;
      display: flex;
      background: var(--dropdownGrey);
      color: var(--secondary);
      border-bottom: none;
      border-radius: 8px 8px 0 0;
      box-shadow: 0px 3px 6px var(--secondSecondaryShadow);

      .p-dropdown-filter,
      .p-dropdown-filter:focus {
        height: 2.5rem;
        padding-left: 1.25rem;
        border: 1px solid var(--grey);
        border-radius: 21px;
      }

      .p-dropdown-filter-icon {
        right: 1.25rem;
      }
    }
  }

  // .p-dropdown-label,
  // .p-dropdown-label.p-placeholder {
  //   padding-left: 1.2rem;
  // }

  .p-dropdown-clearable .p-dropdown-label {
    padding-right: 1.75rem;
    padding-left: 1.25rem;
  }
}

.p-dropdown:not(.p-disabled):hover {
  border-color: none;
}

//? Multiselect global
.p-multiselect {
  border: 1px solid var(--grey);
  border-radius: 0 21px 21px 0;
  height: 2.5rem;
  align-items: center;
  width: 15rem;

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--secondary);
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background: var(--grey);
    border-radius: 4px;
    margin: 1rem;
  }

  .p-multiselect-panel {
    border-radius: 8px;
    box-shadow: 0px 3px 6px var(--secondaryShadow);

    .p-multiselect-items {
      padding: 0.5rem 0;

      .p-multiselect-item {
        padding: 0.5rem 1.25rem;
      }

      .p-multiselect-item.p-highlight,
      .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
        color: var(--primary);
        background: transparent;
      }
    }

    .p-multiselect-header {
      padding: 0.75rem 1.25rem;
      display: flex;
      background: var(--dropdownGrey);
      color: var(--secondary);
      border-bottom: none;
      border-radius: 8px 8px 0 0;
      box-shadow: 0px 3px 6px var(--secondSecondaryShadow);

      .p-multiselect-filter,
      .p-multiselect-filter:focus {
        height: 2.5rem;
        padding-left: 1.25rem;
        border: 1px solid var(--grey);
        border-radius: 21px;
      }

      .p-multiselect-filter-icon {
        right: 1.25rem;
      }

      .p-multiselect-close:enabled:hover {
        color: var(--primary);
        background: var(--thirdGrey);
      }
    }
  }

  .p-multiselect-clearable .p-multiselect-label {
    padding-right: 1.75rem;
    padding-left: 1.25rem;
  }
}

.p-multiselect:not(.p-disabled):hover {
  border-color: none;
}

//? Checkbox global
.p-checkbox {
  width: 21px;
  height: 21px;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  color: var(--white);
  width: 17px;
  height: 17px;
}

.p-checkbox .p-checkbox-box {
  border: 1px solid var(--gray-400);
  background: var(--white);
  width: 21px;
  height: 21px;
  color: var(--secondary);
  border-radius: 4px;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--primary);
  background: var(--primary);
}

.p-calendar {
  width: 17.8rem;
  .p-inputtext {
    // padding-left: 1.2rem;
    height: 2.5rem;
    border: 1px solid var(--grey);
    border-radius: 21px 0 0 21px;
  }

  .p-button.p-button-icon-only {
    height: 2.5rem;
  }

  td {
    padding: 0px !important;
  }

  td > span {
    width: 2rem !important;
    height: 2rem !important;
  }

  .p-datepicker {
    min-width: unset !important;
  }
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0;
}

.p-datepicker .p-monthpicker {
  padding: 0.5rem;
}

.p-monthpicker-month {
  width: 30% !important;
  margin: 0.2rem;
}

.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: var(--white);
  background: var(--primary);
}

.p-datepicker .p-monthpicker .p-monthpicker-month:hover {
  color: var(--white) !important;
  background: var(--primaryOpacity) !important;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: var(--primary);
  border-color: transparent;
  background: none;
}

// order p-calendar
.p-calendar.p-calendar-w-btn .p-inputtext {
  order: 2;
  border-radius: 0px 21px 21px 0px;
}

.p-calendar.p-calendar-w-btn .p-datepicker-trigger {
  order: 1;
  width: 2.8rem;
  padding-left: 0.4rem;
  border: 1px solid var(--primary);
  border-radius: 21px 0px 0px 21px;
  color: var(--primary);
  background: var(--primaryOpacity);

  .p-icon {
    width: 1.4rem;
    height: 1.4rem;
  }
}

.p-calendar.p-calendar-w-btn .p-datepicker-trigger:hover {
  color: var(--primary) !important;
  background: var(--primaryOpacity) !important;
}

//? Datepicker global
.p-datepicker {
  padding: 0.5rem;
  background: var(--white);
  color: var(--secondary);
  border: 1px solid var(--secondary);
  border-radius: 16px;
  box-shadow: 0px 3px 6px var(--secondaryShadow);
}

.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
  color: var(--white);
  background: var(--primary);
}

.p-datepicker .p-yearpicker .p-yearpicker-year:hover {
  color: var(--white) !important;
  background: var(--primaryOpacity) !important;
}

//? Buttons
.p-button {
  border-radius: 32px;
  background: var(--primary);
  color: var(--white);

  .p-button-label {
    font-weight: 300;
  }
}

.p-button.p-button:enabled:hover {
  background: var(--primary);
  color: var(--white);
}

.p-selectbutton .p-button {
  background: var(--white);
  border: 1px solid var(--grey);
  color: var(--grey);
  height: 28px;
  padding: 0px 0.9rem;

  img {
    transition: filter 0.2s;
  }
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: var(--white);
  border-color: var(--primary);
  color: var(--primary);

  img {
    transition: filter 0.2s;
    filter: invert(43%) sepia(73%) saturate(5842%) hue-rotate(6deg) brightness(106%) contrast(103%);
  }
}

.p-selectbutton .p-button.p-highlight {
  background: var(--primaryOpacity);
  border-color: var(--primary);
  color: var(--primary);
}

.p-selectbutton .p-button.p-highlight:hover {
  background: var(--primaryOpacity);
  border-color: var(--primary);
  color: var(--primary);
}

.p-selectbutton .p-button:focus,
.p-selectbutton .p-button.p-highlight:focus {
  box-shadow: none;
}

.p-button.p-button-outlined {
  background-color: transparent;
  color: var(--primary);
  border: 1px solid;

  .p-button-label {
    font-weight: 400;
  }
}

.p-button.p-button-outlined:enabled:hover {
  color: var(--primary);
  background: none;
}

//? Custom sidebar
.p-sidebar {
  background-color: var(--primary);
}

.p-sidebar .p-sidebar-header {
  padding: 1rem;
  background: var(--white);
  box-shadow: 0px 3px 6px var(--secondary);
}

.p-sidebar .p-sidebar-content {
  padding: 0;
}

.p-sidebar .p-sidebar-footer {
  padding: 0;
}

//? Custom info messages
.p-message.p-message-error {
  width: 100% !important;
  border: none;
  border-width: 0 0 0 6px;
  border-radius: 40px;
}

.p-message.p-message-warn {
  width: 100% !important;
  border: none;
  border-width: 0 0 0 6px;
  border-radius: 40px;
}

.p-message .p-message-detail {
  font-weight: 400;
}

.p-message .p-message-icon {
  font-size: 1rem;
}

.p-message.p-message-error .p-message-icon,
.p-message.p-message-warn .p-message-icon {
  padding-top: 5px;
}

.p-message .p-message-wrapper {
  padding: 1rem 1.75rem;
}

.p-message-wrapper {
  justify-content: center;
}

//? Listbox
.p-listbox {
  background: var(--white);
  color: var(--secondary);
  border: 1px solid var(--grey);
  border-top: none;
  border-radius: 9px;

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--secondary);
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background: var(--grey);
    border-radius: 4px;
    margin: 1rem;
  }

  .p-listbox-header {
    border: 1px solid var(--primary);
    color: var(--secondary);
    background: var(--white);
    border-top-right-radius: 9px;
    border-top-left-radius: 9px;

    .p-listbox-filter,
    .p-listbox-filter:focus {
      height: 2.5rem;
      padding-left: 1.25rem;
      border: 1px solid var(--grey);
      border-radius: 21px;
    }

    .p-listbox-filter-icon {
      right: 1.25rem;
    }
  }

  .p-listbox-list {
    .p-listbox-item {
      padding: 0.5rem 1.25rem;
      color: var(--secondary);
    }

    .p-listbox-item.p-highlight {
      color: var(--secondary);
      background: transparent;
    }
  }

  &:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
    color: var(--primary);
    background: transparent;
  }
}

//? slider
.p-slider {
  background: var(--grey);

  .p-slider-range {
    background: var(--primary);
  }
}

//? Treeselect global
.p-treeselect {
  border: 1px solid var(--grey);
  border-radius: 0 21px 21px 0;
  height: 2.5rem;
  align-items: center;
  width: 15rem;

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--secondary);
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background: var(--grey);
    border-radius: 4px;
    margin: 1rem;
  }

  .p-treeselect-panel {
    border-radius: 8px;
    box-shadow: 0px 3px 6px var(--secondaryShadow);

    .p-treeselect-header {
      padding: 0.75rem 1.25rem;
      display: flex;
      background: var(--dropdownGrey);
      color: var(--secondary);
      border-bottom: none;
      border-radius: 8px 8px 0 0;
      box-shadow: 0px 3px 6px var(--secondSecondaryShadow);

      .p-treeselect-filter,
      .p-treeselect-filter:focus {
        height: 2.5rem;
        padding-left: 1.25rem;
        border: 1px solid var(--grey);
        border-radius: 21px;
      }

      .p-treeselect-filter-icon {
        right: 1.25rem;
      }

      .p-treeselect-close:enabled:hover {
        color: var(--primary);
        background: var(--thirdGrey);
      }

      .p-treeselect-close {
        .p-treeselect-filter-icon {
          right: 0.5rem;
        }
      }
    }

    .p-treeselect-items-wrapper {
      .p-tree {
        background-color: transparent;
        padding: 0.5rem 0;

        .p-tree-container {
          .p-treenode {
            padding: 0;

            .p-treenode-content {
              padding: 0.5rem 1.25rem 0.5rem 0;
            }

            .p-treenode-content.p-highlight {
              background-color: var(--white);
              color: var(--primary);
            }

            .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
              background-color: var(--white);
              color: var(--primary);
            }
          }
        }

        .p-tree-empty-message {
          padding: 0.75rem 1.25rem;
        }
      }
    }
  }
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
  color: var(--secondary);
  border-color: transparent;
  background: none;
}

//? radiobutton
.p-radiobutton .p-radiobutton-box {
  border: 1px solid var(--gray-400);
  background: var(--white);
  width: 22px;
  height: 22px;
  color: var(--secondary);
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  border: 1px solid var(--gray-400);
}

.p-radiobutton-label {
  cursor: pointer;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: var(--gray-400);
  background: var(--white);
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: var(--primary);
  background: none;
  color: var(--primary);
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 14px;
  height: 14px;
  color: var(--primary);
}
