//! --- Section of material --- //
//? Dialog global
.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 33px !important;
  box-shadow: 0px 3px 6px var(--secondSecondaryShadow) !important;
  padding: 1.5rem !important;
}

.mdc-dialog__title::before {
  content: none !important;
}

.mat-mdc-dialog-actions {
  margin-top: 2rem !important;
  justify-content: center !important;
}

//? Buttons global
.mat-mdc-raised-button:not(:disabled),
.mat-mdc-outlined-button:not(:disabled),
.mat-mdc-unelevated-button:not(:disabled),
.mat-mdc-button:not(:disabled),
.mat-mdc-raised-button[disabled][disabled],
.mat-mdc-outlined-button[disabled][disabled],
.mat-mdc-unelevated-button[disabled][disabled],
.mat-mdc-button[disabled][disabled] {
  border-radius: 20px !important;
  min-width: 7rem !important;
  height: 2.5rem;
}

.mat-mdc-outlined-button:not(:disabled) {
  border: 1px solid var(--primary) !important;
}

.mdc-button__label {
  display: flex;
  align-items: center;
}

// switch button
.mdc-switch--unselected,
.mdc-switch--selected {
  .mdc-switch__handle-track {
    .mdc-switch__handle {
      .mdc-switch__icons {
        display: none;
      }

      .mdc-switch__shadow {
        background-color: var(--secondary);
      }
    }
  }
}

.mdc-switch--selected {
  .mdc-switch__handle-track {
    .mdc-switch__handle {
      .mdc-switch__shadow {
        background-color: var(--primary);
      }
    }
  }
}

//? Forms/inputs
.mdc-text-field--filled:not(.mdc-text-field--disabled),
.mdc-text-field--no-label:not(.mdc-text-field--disabled) {
  border: 1px solid var(--grey);
  border-radius: 32px;
  background: var(--white);
  height: 3.4rem;
  max-width: 22rem;
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) {
  background: var(--white) !important;
  border: 1px solid var(--primary);
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0;
}

.mdc-text-field--disabled.mdc-text-field--filled {
  border: 1px solid var(--grey);
  border-radius: 32px;
  background: var(--inputDisabled);
  height: 3.4rem;
  max-width: 22rem;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-left: 0.2rem;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple:active,
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple:focus,
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple:hover,
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-line-ripple:active,
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-line-ripple:focus,
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-line-ripple:hover,
.mdc-text-field--no-label:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.mdc-text-field--no-label:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.mdc-text-field--no-label:not(.mdc-text-field--disabled) .mdc-line-ripple:active,
.mdc-text-field--no-label:not(.mdc-text-field--disabled) .mdc-line-ripple:focus,
.mdc-text-field--no-label:not(.mdc-text-field--disabled) .mdc-line-ripple:hover,
.mdc-text-field--no-label:not(.mdc-text-field--disabled) .mdc-line-ripple {
  border: none;
}

//Tooltips
.mat-mdc-tooltip .mdc-tooltip__surface {
  font-size: 14px;
  color: var(--secondary);
  background-color: var(--white);
  padding: 0.6rem;
  box-shadow: 0px 3px 6px var(--thirdSecondaryShadow);
  border-radius: 9px;
  user-select: none;
}

// mat-tap-group
mat-tab-group {
  width: 100%;

  .mat-mdc-tab {
    flex-grow: 0 !important;
    margin: 0;
    padding: 0rem 1.2rem;
  }

  .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
  .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
    color: var(--primary);
    opacity: 1;
    font-weight: 500;
  }

  .mat-tab-label,
  .mat-tab-label-active {
    min-width: 100px;
  }
}

.mat-mdc-tab-header {
  border: 1.5px solid var(--primary);
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

//dropdowns
.cdk-overlay-pane:not(.mat-mdc-select-panel-above) .mdc-menu-surface.mat-mdc-select-panel {
  transform-origin: top center;
  border-radius: 8px;
  margin-top: 0.25rem;
}
