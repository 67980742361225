@use '@angular/material' as mat;
@import 'themes/material/theming';
@import 'scss/material-custom-styles';
@import 'scss/prime-custom-styles';

:root {
  //! if you want to add new color ask to Sebastián Barcia Beltrán or Jose miguel Fernandez
  --primary: #159896;
  --primaryOpacity: #25c0bd;
  --primaryAlternative: #159896;
  --secondary: #474751;
  --grey: #cec6c0;
  --secondGrey: #cec6c033;
  --thirdGrey: #f3f1ef;
  --inputGrey: #ffffffb3;
  --inputDisabled: #eeebe9;
  --tableGrey: #efedeb;
  --dropdownGrey: #e4e4e4;
  --white: #ffffff;
  --primaryShadow: #159896c3;
  --secondaryShadow: #47475163;
  --secondSecondaryShadow: #00000029;
  --thirdSecondaryShadow: #00000080;
  --redMessage: #a40303;
  --greenMessage: #028d2f;
  --alarmMedium: #af765d;
}

@font-face {
  font-family: 'Outfit';
  src: url('/assets/fonts/Outfit-Regular.ttf') format('truetype');
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font: normal 16px Outfit, sans-serif;
}

html,
body {
  height: 100%;
  font-size: 16px;
  font-family: 'Outfit', sans-serif;
}

p {
  margin: 0 !important;
}

.icon {
  width: 15px;
  height: 15px;
}

//! this is necesary for the responsive
img {
  max-width: 100%;
  /*¿porque no width 100%? porque si tenemos una imagen pequeña de 200px con
  muy mala resolución esa imagen va a crecer y va hacer que se vea con muy
  mala calidad muy distorcionada.
  Colocando max-widh nunca va a crecer la imagen más de lo que es su tamaño
  natural */
}

// Scrollbars styles
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background: var(--grey);
  border-radius: 4px;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

// Fix bug in popup dialog, overflows container
.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: hidden;
}

// Colors class for svg icons
.svg-primary {
  filter: invert(43%) sepia(73%) saturate(5842%) hue-rotate(6deg) brightness(106%) contrast(103%);
}

.svg-primary-disabled {
  filter: invert(100%) sepia(34%) saturate(473%) hue-rotate(315deg) brightness(99%) contrast(108%);
}

.svg-secondary {
  filter: brightness(0) saturate(100%) invert(26%) sepia(7%) saturate(654%) hue-rotate(212deg)
    brightness(96%) contrast(91%);
}

.svg-white {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%) hue-rotate(11deg)
    brightness(104%) contrast(100%);
}

.svg-grey {
  filter: invert(82%) sepia(14%) saturate(131%) hue-rotate(343deg) brightness(97%) contrast(87%);
}

//! GLOBAL SPECIFIC CLASSES
//? styles for the dinamic box
.global-show-more {
  margin-bottom: -40px;
  z-index: 9;

  .mat-mdc-button,
  .mat-mdc-outlined-button {
    padding: 0 1.25rem;
    --mat-mdc-button-persistent-ripple-color: none;
    --mat-mdc-button-ripple-color: none;
  }

  .mdc-button__label {
    padding-top: 0.5rem;
  }

  i {
    padding-left: 0.3rem;
  }
}

//? Specific button clean filters
.global-clean-filters {
  button {
    background-color: var(--white) !important;
    border: 1px solid var(--primary) !important;
    color: var(--primary) !important;
  }

  .mat-mdc-unelevated-button:not(:disabled):hover,
  .mat-mdc-unelevated-button[disabled][disabled]:hover {
    background-color: var(--primaryShadow) !important;
  }
}

// specific class for search tables
.global-mat-search {
  padding-bottom: 1rem;

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    height: 2.5rem;
    width: 14rem;
  }

  .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
    opacity: 0;
  }

  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea)
    .mat-mdc-form-field-infix {
    margin: auto;
    padding: 9px 10px 10px 5px;
  }

  .mat-mdc-form-field-icon-suffix,
  .mat-mdc-form-field-icon-prefix {
    display: contents;
  }

  .mat-mdc-form-field-icon-prefix > .mat-icon,
  .mat-mdc-form-field-icon-suffix > .mat-icon {
    padding: 0.5rem 1rem 0 1rem;
    color: var(--secondary);
  }

  .mat-mdc-form-field-subscript-wrapper {
    height: 0;
  }
}

//! Specific classes for mat-group and his containers
.global-undermap-container-down {
  position: absolute;
  bottom: -53vh;
  width: 100%;
  z-index: 1;
  transition: 1.5s ease;
}

.global-undermap-container-up {
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: 1;
  transition: 1.5s ease;
}

.global-general-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: var(--white);
  width: 100%;
  border-right: 1px solid var(--grey);
  border-left: 1px solid var(--grey);
  border-bottom: 1px solid var(--grey);
  box-shadow: 0px -6px 6px var(--secondSecondaryShadow);
  border-radius: 24px;
  overflow: hidden;

  mat-tab-group {
    .tab-cont {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      height: 53vh;
      background: var(--white);

      .graph-controls {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        padding: 1rem 1rem 0rem 1rem;

        .small-button {
          display: flex;
          align-items: center;
          height: 28px !important;
          margin: 0 0.9rem;
          width: fit-content;
          min-width: 0 !important;

          img {
            padding-right: 0.4rem;
            width: 1.5rem;
          }
        }

        .clean-comparative {
          margin: 0 0.9rem 0 0;

          img {
            padding-right: 0.4rem;
          }
        }

        .select-button {
          img {
            padding-right: 0.4rem;
          }
        }
      }

      .graph {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        height: fit-content;
      }

      .table-cont {
        width: 100%;
        padding: 1rem 1rem 0;
        background-color: var(--white);
        height: calc(100% - 45px - 1rem);
      }
    }
  }
}

// play with the visibility
.global-visibility-visible {
  visibility: visible;
}

.global-visibility-hidden {
  visibility: hidden;
}

.global-disabled {
  opacity: 0.5;
}

// pointer
.global-cursor-pointer {
  cursor: pointer;
}

// margin in dialog buttons
.global-btn-left-separation {
  margin-left: 1.7rem !important;
}

// class that makes the p-overlay bigger when text is long of the p-treeSelect
.global-show-overlay .p-overlay {
  width: 520px !important;
}

// class that moves the p-overlay to the right of the p-treeSelect
.global-move-overlay .p-overlay {
  left: -160px !important;
}

// class that moves the p-overlay to the right of the p-treeSelect
.global-extra-move-overlay .p-overlay {
  left: -280px !important;
}

// Comparative overlaypanel
.global-comparative-overlaypanel {
  border-radius: 16px;
  box-shadow: 0px 6px 6px var(--secondSecondaryShadow);

  h2 {
    color: var(--primary);
    text-align: left;
    font-size: 1.125rem;
    font-weight: 600;
  }

  .comparative-section {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 100%;
    gap: 0.5rem;

    p {
      color: var(--secondary);
      font-weight: 600;
    }

    p-calendar {
      padding-bottom: 0.8rem;
    }

    .p-calendar-w-btn .p-inputtext {
      font-size: 15px;
    }
  }

  #actions {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1.5rem;
  }
}

// global class loading progress bar and loading card
.global-progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
  margin: auto;
  text-align: center;
  background-color: var(--white);

  p {
    margin: 5px;
  }
}

.global-progress-card-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 100%;
  min-height: 90px;
  gap: 0.25rem;
  padding: 0 1rem;
}

//! GLOBAL MAP CONTROL STYLES
#map .ol-zoom {
  bottom: 2.65rem;
  top: auto;
  left: 1.25rem;
  right: auto;
}

#mapCurrentState .ol-zoom,
#mapStateOfOts .ol-zoom,
#mapBuildingsCurrentState .ol-zoom {
  bottom: 1.25rem;
  top: auto;
  left: 1.25rem;
  right: auto;
}

.ol-control {
  background-color: transparent;
  font-family: 'Outfit', sans-serif;
  font-size: 1em;

  button {
    background-color: var(--white);
    color: var(--secondary);
    box-shadow: 0px 3px 6px var(--thirdSecondaryShadow);
    width: 1.2em;
    height: 1.2em;
    font-size: 1.8em;
    border-radius: 50%;

    &:focus {
      background-color: none;
      outline: none;
    }

    &:hover {
      background-color: var(--primaryShadow);
      cursor: pointer;
      outline: none;
    }
  }
}

.ol-zoom .ol-zoom-in {
  margin-bottom: 0.3rem;
}

//! END GLOBAL MAP CONTROL STYLES
